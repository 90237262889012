import React, { useEffect, useState } from 'react'
import { useShoppingCart } from '../../context/ShoppingCartContext';
import Cart from '../Cart/Cart'
import icon from '../../assets/icons/cart.svg'
import Product from './Product'
import menuList from '../../utils/products.json'
import axios from 'axios'
import { Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Collection = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);

  // Single state for the selected category
  const [selectedCategory, setSelectedCategory] = useState('All Products');

  useEffect(() => {
    const path = location.pathname;
    const extractedValue = path.split('/collection/')[1];
    console.log(extractedValue)
    if (extractedValue) { setSelectedCategory(extractedValue) }


    // setCollectionName(extractedValue);
  }, [location]);

  useEffect(() => {

    const getproducts = async () => {
      try {
        const data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/getproducts`)

        console.log(data.data);
        setFilteredProducts(data.data);
        setAllProducts(data.data);
      }
      catch (error) { console.log('There was an error getting the products!', error); }
    }

    getproducts();

  }, [])

  // Filter products based on selected category
  useEffect(() => {
    if (selectedCategory === 'All Products') {
      setFilteredProducts(allProducts);  // Show all products
    } else {
      const filtered = allProducts.filter(product => product.category === selectedCategory);
      setFilteredProducts(filtered);  // Show filtered products
    }
  }, [selectedCategory, allProducts]);

  return (

    <>
      <div className='Collection'>


        <div className='flex gap-4 lg:gap-8'>
          <button
            className={selectedCategory === 'All Products' ? 'C_shopBtns mobile selected' : 'C_shopBtns mobile'}
            onClick={() => setSelectedCategory('All Products')}
          >
            Shop all products
          </button>

          <button
            className={selectedCategory === 'Rings' ? 'C_shopBtns selected' : 'C_shopBtns'}
            onClick={() => setSelectedCategory('Rings')}
          >
            Shop rings
          </button>

          <button
            className={selectedCategory === 'Earrings' ? 'C_shopBtns selected' : 'C_shopBtns'}
            onClick={() => setSelectedCategory('Earrings')}
          >
            Shop earrings
          </button>


          <button
            className={selectedCategory === 'Wristwear' ? 'C_shopBtns selected' : 'C_shopBtns'}
            onClick={() => setSelectedCategory('Wristwear')}
          >
            Shop wristwear
          </button>

          <button
            className={selectedCategory === 'Neckpieces' ? 'C_shopBtns selected' : 'C_shopBtns'}
            onClick={() => setSelectedCategory('Neckpieces')}
          >
            Shop neckpieces
          </button>

        </div>


        <div className='text-[10px] text-white text-left px-4'>
          <div>
            <Link to='/' className='pinkHover'>HOME</Link> / SHOP <span className='uppercase'>{selectedCategory}</span>
          </div>
          <div>{filteredProducts.length} RESULTS</div>
        </div>


        <div className='productCarousel'>

          {filteredProducts.length > 0 && filteredProducts.map((item, i) =>
            // <Link to={`/product/${item.code}`} key={i} >
            <Product item={item} key={i} />
            // </Link>
          )}

          {filteredProducts.length == 0 &&
            <>
              <Skeleton variant="rectangular" height={200} sx={{ bgcolor: 'grey.800' }} />
              <Skeleton variant="rectangular" height={200} sx={{ bgcolor: 'grey.800' }} />
              <Skeleton variant="rectangular" height={200} sx={{ bgcolor: 'grey.800' }} />
              <Skeleton variant="rectangular" height={200} sx={{ bgcolor: 'grey.800' }} />
            </>
          }

        </div>


        <div className='flex flex-col items-center gap-2 my-8'>
          <div className='font-subheading text-white'>
            showing {filteredProducts.length} out of {allProducts.length} items
          </div>
          <button className='text-white w-[160px]'>load more</button>
        </div>


        <div className='max-w-[1100px] mx-auto w-full'>
          <div className='font-heading text-white text-[48px] text-left'>See More</div>
          <div className='flex gap-4'>
            <div className='bg-white w-full h-[250px] relative'>
              <div className='absolute bottom-4 left-0 right-0 seemoreLink'>EXPLORE NEW pendants</div>
            </div>
            <div className='bg-white w-full h-[250px] relative'>
              <div className='absolute bottom-4 left-0 right-0 seemoreLink'>EXPLORE earrings</div>
            </div>
          </div>
        </div>

      </div>

      {/* {cartIsOpen ?
        <Cart />
        :
        
      } */}
    </>
  )
}

export default Collection